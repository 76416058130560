@import "font";

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

$sidebar-bg-color: #060f17;
$icon-size: 20px;
$sidebar-collapsed-width: 79px;
$sidebar-width: 100%;
$sidebar-color: #e5e5e5;
$highlight-color: #e5e5e5;
// $sidebar-bg-color: #1d1d1d !default;
// $sidebar-color: #adadad !default;
// $sidebar-width: 270px !default;
// $sidebar-collapsed-width: 80px !default;
// $highlight-color: #d8d8d8 !default;
// $submenu-bg-color: #2b2b2b !default;
// $submenu-bg-color-collapsed: #2b2b2b !default;
// $icon-bg-color: #2b2b2b !default;
// $icon-size: 35px !default;
// $submenu-indent: 24px !default;
// $breakpoint-xs: 480px !default;
// $breakpoint-sm: 576px !default;
// $breakpoint-md: 768px !default;
// $breakpoint-lg: 992px !default;
// $breakpoint-xl: 1200px !default;
// $breakpoint-xxl: 1600px !default;
@import "~react-pro-sidebar/dist/scss/styles.scss";
@import "react-date-range/dist/styles.css"; // main style file for react-date-picker
@import "react-date-range/dist/theme/default.css"; // theme css file for react-date-picker

* {
  letter-spacing: 1px;
}
body {
  font-family: "Archivo";
  overflow-x: hidden;
}

// for floating label
.floatiglabel {
  input:focus-within ~ label,
  input:not(:placeholder-shown) ~ label {
    @apply transform scale-75 -translate-y-4 text-smalltext top-[5px];
  }

  textarea:focus-within ~ label,
  textarea:not(:placeholder-shown) ~ label {
    @apply transform scale-75 -translate-y-4 text-smalltext top-[5px];
    padding: 0px 6px;
  }
}

.css-1pahdxg-control {
  border-color: #c2c2c2 !important;
  box-shadow: none !important;
}

.css-1pahdxg-control:hover {
  border-color: #50c878 !important;
  @apply ring-0;
}

.css-14el2xx-placeholder,
.css-qc6sy-singleValue {
  @apply text-body1;
}
.css-26l3qy-menu {
  background-color: white !important;
  z-index: 2 !important;
}

.css-1n7v3ny-option {
  background-color: #50c878 !important;
  color: white !important;
}

.css-9gakcf-option {
  background-color: #50c878 !important;
}

.css-319lph-ValueContainer {
  padding: 0.5rem 8px !important;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.pro-sidebar {
  transition: all 0.2s ease;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 10px 8px !important;
  justify-content: center;
}

.pro-sidebar .pro-menu {
  padding: 0;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  margin-right: 5px;
}

.pro-sidebar-layout {
  transition: all 0.2s ease;
}

// .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
//   margin-bottom: 80px;
// }

// .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
//   // margin-top: 80px;
// }

.css-4ljt47-MenuList {
  @apply flex flex-col gap-[3px];
}

.css-tlfecz-indicatorContainer {
  padding: 5px !important;
}

.rdrDateDisplay,
.rdrStartEdge,
.rdrInRange,
.rdrEndEdge {
  color: #50c878 !important;
}

.rdrDayStartPreview,
.rdrDayEndPreview {
  @apply focus-visible:outline-none outline-offset-0 border-0;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #50c878;
}

.rdrDayInPreview {
  color: #50c878 !important;
}

.css-319lph-ValueContainer {
  @apply capitalize;
}

select:focus {
  --tw-ring-color: #50c878;
}

.table_link {
  @apply text-primary-main cursor-pointer border-b-0.5 border-primary-main pb-1;
}

.component_filter_list_details {
  @apply w-full flex justify-between items-center px-6 py-5 gap-5 bg-white shadow-sm;
}

.component_filter_badges {
  @apply h-full flex items-center;
}

.component_filter_title {
  @apply text-body1 pr-2 border-r-1 text-font-textcolor1;
}

.component_separates {
  @apply flex flex-row flex-wrap gap-4 pl-4;
}

.component_badge {
  @apply bg-[#F3F4F6] text-font-textcolor1 rounded-full p-1 px-3 text-body1 font-medium cursor-default;
}

.component_clearall {
  @apply pl-2 text-body1 border-l-1 cursor-pointer;
}

.component_error_toast {
  @apply bg-[#FEF2F2] w-[432px] h-[68px] flex justify-start items-center rounded-sm p-3;
}

.component_toast_icon {
  @apply px-8 w-[10%] flex justify-center items-start;
}

.component_toast_error_text {
  @apply text-error-main text-body1 w-[80%];
}
.component_toast_close_text {
  @apply w-[10%] cursor-pointer  flex justify-center items-start;
}

.component_success_toast {
  @apply bg-[#ECFDF5] w-[432px] h-[68px] flex justify-start items-center rounded-sm p-3;
}

.component_toast_icon {
  @apply px-8 w-[10%] flex justify-center items-start;
}

.component_toast_success_text {
  @apply text-primary-main text-body1 w-[80%];
}
.component_toast_close_icon {
  @apply w-[10%] cursor-pointer  flex justify-center items-start;
}

.ps__rail-y {
  opacity: 0.6 !important;
  left: auto !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus,
.css-1x5jdmq:focus {
  outline: 0;
  --tw-ring-color: transparent;
  border-color: #50c878;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-1x5jdmq {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
  outline: none !important;
  --tw-ring-color: transparent;
  @apply text-others-black;
  border-color: #c2c2c2 !important;
}

.css-1uvydh2,
.css-1uvydh2:focus,
.css-1bn53lx,
.css-1bn53lx:hover {
  padding-top: 4.5px !important;
  padding-bottom: 4.5px !important;
  outline: none !important;
  --tw-ring-color: transparent;
  border-color: #50c878 !important;
}

.css-1bn53lx.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  border-color: #50c878 !important;
}

.css-1bn53lx:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root,
.css-u9osun.Mui-focused,
.css-u9osun {
  outline: none !important;
  font-family: "Archivo" !important;
  letter-spacing: 1px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-p0rm37 {
  outline: 1px solid transparent !important;
  outline-offset: 1px !important;
  top: -6px !important;
  font-size: 14px !important;
  font-family: "Archivo" !important;
  letter-spacing: 1px !important;
  @apply capitalize;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
.css-1v4ccyo {
  outline-color: #50c878 !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  outline: none !important;
  --tw-ring-color: none;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:focus-visible,
.css-1v4ccyo:focus-visible {
  outline: 1px solid transparent;
  outline-offset: 1px;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.css-u9osun.Mui-focused {
  color: #9b9b9b !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  border-width: 1px !important;
  outline: none;
}

.css-igs3ac {
  border-color: #50c878;
}

.css-fvipm8:hover .MuiOutlinedInput-notchedOutline,
.css-1hof3tc:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
.css-i44wyl {
  @apply w-full;
  outline: none;
  --tw-ring-color: none;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.css-1x5jdmq.Mui-disabled {
  @apply text-others-black;
  -webkit-text-fill-color: #000000;
}

// input[type=time]::-webkit-datetime-edit {
//   color: transparent;
// }

.css-1v4ccyo.Mui-focused:focus .MuiOutlinedInput-notchedOutline:focus,
.css-igs3ac:focus {
  border-color: #50c878;
}

.css-1v4ccyo:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  border-width: 1px !important;
}

.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input:focus,
.css-u36398:focus {
  --tw-ring-color: none;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root,
.css-1ixds2g {
  outline: none !important;
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-1hof3tc.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  border-width: 1px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-qiwgdb {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  font-family: "Archivo" !important;
  color: #333333 !important;
  @apply border-others-iconcolorlight;
  letter-spacing: 1px !important;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.css-fvipm8.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  border-width: 1px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
.css-1km1ehz {
  font-family: "Archivo" !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  margin-bottom: 0.25rem !important;
  letter-spacing: 1px !important;

  &:last-child {
    margin-bottom: 0px !important;
  }
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected,
.css-1km1ehz.Mui-selected {
  background-color: #50c878 !important;
  color: #ffffff !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover,
.css-1km1ehz:hover {
  background-color: #50c878 !important;
  color: #ffffff !important;
}

.dashboard_cal_icon {
  @apply bg-white rounded-lg py-[6px] px-2 cursor-pointer shadow;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  border-width: 1px !important;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input:focus {
  --tw-ring-color: none !important;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  --tw-ring-color: none !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover
  .MuiOutlinedInput-notchedOutline,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  --tw-ring-color: none !important;
  border-color: #50c878 !important;
  border-width: 1px !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  --tw-ring-color: none !important;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root,
.css-1ufn0jl {
  padding-left: 0px !important;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
  letter-spacing: 1px !important;
  // padding-left: 16px !important;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-1ixds2g:focus {
  outline: none !important;
  --tw-ring-color: none !important;
}

// .css-1ixds2g {
//   padding-left: 2.25rem !important;
// }

.css-1ufn0jl {
  background-color: white;
}

.css-1ufn0jl.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  border-width: 1px !important;
}

.css-1ufn0jl:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

//start css for contact dropdown listbox
.listbox_buttons {
  @apply relative w-[14%] py-2 pl-3 pr-0 text-left bg-white cursor-default focus:border-primary-main focus-visible:outline-none text-[16px];
}

.listbox_gaps {
  @apply flex items-center gap-x-2;
}

.listbox_image {
  @apply flex-shrink-0 h-6 w-6;
}

.listbox_dropdown {
  @apply absolute w-full top-[5px] left-0 z-20 py-1 mt-9 overflow-auto text-base bg-white shadow-lg
  max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm;
}
//end css for contact dropdown listbox

// css for filter modal
.filtermodal_container {
  @apply flex flex-col justify-center items-center w-full p-10 gap-7;

  .filtermodal_inputs {
    @apply w-full flex flex-col justify-between gap-7;
  }

  .filtermodal_btns {
    @apply flex gap-2 mt-4;
  }
}

.searchingicon {
  @apply absolute inset-y-0 left-3 flex items-center text-[#C2C2C2];
}

.searchingbox {
  @apply bg-primary-main absolute text-white inset-y-0 right-0 p-3 flex items-center cursor-pointer rounded-r;
}

.input_searchbox {
  width: 321px !important;
  background: white !important;
  --tw-ring-color: none !important;

  .css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root,
  .css-x0qxq3 {
    padding-left: 0px !important;
    border-color: unset !important;
    --tw-ring-color: none !important;
  }

  input {
    @apply bg-white;
    padding-top: 9.5px !important;
    padding-bottom: 9.5px !important;
    padding-left: 35px !important;
    font-size: 14px !important;
    font-family: Archivo !important;
    letter-spacing: 1px !important;
    outline: none !important;
  }

  @media screen and (max-width: 1023px) {
    width: 270px !important;
  }
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.spinner {
  width: 60px;
  height: 60px;
  border: 8px solid;
  border-color: #50c878 transparent #50c878 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;

  @media screen and (max-width: 1023px) {
    width: 50px;
    height: 50px;
    border: 6px solid;
    border-color: #50c878 transparent #50c878 transparent;
  }
}

.data-container {
  width: 87%;
  // height: 10%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  // background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.data-spinner {
  width: 60px;
  height: 60px;
  border: 8px solid;
  border-color: #50c878 transparent #50c878 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;

  @media screen and (max-width: 1023px) {
    width: 50px;
    height: 50px;
    border: 6px solid;
    border-color: #50c878 transparent #50c878 transparent;
  }
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #ecfdf5 !important;
  color: #065f46 !important;
  font-weight: 500;
}

.Toastify__toast-body {
  align-items: flex-start;
}

.Toastify__toast-icon {
  align-items: flex-start;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  .Toastify__toast-body {
    .Toastify__toast-icon {
      color: #50c878 !important;
    }
  }

  .Toastify__close-button {
    color: #50c878 !important;
  }
}

.Toastify__toast-container {
  @apply w-[360px] font-sans items-center text-body1;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #fef2f2 !important;
  color: #991b1b !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  .Toastify__toast-body {
    .Toastify__toast-icon {
      color: #f87171 !important;
    }
  }

  .Toastify__close-button {
    color: #991b1b !important;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #fffbeb !important;
  color: #b45309 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  .Toastify__toast-body {
    .Toastify__toast-icon {
      color: #fbbf24 !important;
    }
  }

  .Toastify__close-button {
    color: #92400e !important;
  }
}

.css-1qqsdnr-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input,
.css-xxees4 .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0px 0px 0px 0px !important;
  width: 100% !important;
  font-size: 14px !important;
  font-family: Archivo !important;
  color: #333 !important;
  letter-spacing: 1px !important;
  text-transform: capitalize;
  --tw-ring-color: none !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-segi59.Mui-focused .MuiOutlinedInput-notchedOutline,
.css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  border-width: 1px !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.css-segi59:hover .MuiOutlinedInput-notchedOutline,
.css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.css-1si533m:hover .MuiOutlinedInput-notchedOutline,
.css-1si533m.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-1q79v3g-MuiButtonBase-root-MuiChip-root {
  background-color: #f3f4f6 !important;
}

.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused,
.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"],
.css-ue1yok .MuiAutocomplete-option[aria-selected="true"],
.css-ue1yok .MuiAutocomplete-option.Mui-focused {
  background-color: #50c878 !important;
  color: #fff !important;
}

.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option,
.css-ue1yok .MuiAutocomplete-option {
  font-family: Archivo !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  margin-bottom: 0.25rem !important;
  letter-spacing: 1px !important;
}

.css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
  border-width: 1px !important;
}

.css-152mnda-MuiInputBase-input-MuiOutlinedInput-input:focus,
.css-1gnht4k:focus {
  --tw-ring-color: none !important;
  outline-offset: 0 !important;
  border-color: unset !important;
}

.css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.css-x0qxq3:hover .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

.css-x0qxq3.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #50c878 !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.table.sticky [data-sticky-first-right-td] {
  box-shadow: -2px 0px 3px #ccc;
}

.errormsg {
  @apply font-medium text-body2 text-left text-input-error mt-[6px] w-full;
}

// delete modal css
.deletemodel_container {
  @apply flex flex-col justify-center items-center w-full py-8;
}

.deletemodel_title {
  @apply w-[80%] text-center text-2xl xl:text-h6 font-semibold mb-5 font-display;
}

.deletemodel_lable {
  @apply w-[80%] text-mediumtext text-font-textcolor1 font-normal mb-0 text-center;
}

.deletemodel_btn {
  @apply mt-10 flex gap-6;
}

.cancel_btn {
  background: #e5e5e5 !important;
  border: 2px solid #e5e5e5 !important;
  color: #000000 !important;
}
